import { render, staticRenderFns } from "./commonComponent.vue?vue&type=template&id=35ac39dc&scoped=true&"
import script from "./commonComponent.vue?vue&type=script&lang=js&"
export * from "./commonComponent.vue?vue&type=script&lang=js&"
import style0 from "./commonComponent.vue?vue&type=style&index=0&id=35ac39dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ac39dc",
  null
  
)

export default component.exports